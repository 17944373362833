import _cssSyntaxError from "./css-syntax-error";
import _declaration from "./declaration";
import _lazyResult from "./lazy-result";
import _container from "./container";
import _processor from "./processor";
import _stringify from "./stringify";
import _fromJSON from "./fromJSON";
import _document from "./document";
import _warning from "./warning";
import _comment from "./comment";
import _atRule from "./at-rule";
import _result from "./result.js";
import _input from "./input";
import _parse from "./parse";
import _list from "./list";
import _rule from "./rule";
import _root from "./root";
import _node from "./node";
import _process from "process";
var exports = {};
var process = _process;
let CssSyntaxError = _cssSyntaxError;
let Declaration = _declaration;
let LazyResult = _lazyResult;
let Container = _container;
let Processor = _processor;
let stringify = _stringify;
let fromJSON = _fromJSON;
let Document = _document;
let Warning = _warning;
let Comment = _comment;
let AtRule = _atRule;
let Result = _result;
let Input = _input;
let parse = _parse;
let list = _list;
let Rule = _rule;
let Root = _root;
let Node = _node;

function postcss(...plugins) {
  if (plugins.length === 1 && Array.isArray(plugins[0])) {
    plugins = plugins[0];
  }

  return new Processor(plugins);
}

postcss.plugin = function plugin(name, initializer) {
  // eslint-disable-next-line no-console
  if (console && console.warn) {
    // eslint-disable-next-line no-console
    console.warn(name + ": postcss.plugin was deprecated. Migration guide:\n" + "https://evilmartians.com/chronicles/postcss-8-plugin-migration");

    if (process.env.LANG && process.env.LANG.startsWith("cn")) {
      /* c8 ignore next 7 */
      // eslint-disable-next-line no-console
      console.warn(name + ": \u91CC\u9762 postcss.plugin \u88AB\u5F03\u7528. \u8FC1\u79FB\u6307\u5357:\n" + "https://www.w3ctech.com/topic/2226");
    }
  }

  function creator(...args) {
    let transformer = initializer(...args);
    transformer.postcssPlugin = name;
    transformer.postcssVersion = new Processor().version;
    return transformer;
  }

  let cache;
  Object.defineProperty(creator, "postcss", {
    get() {
      if (!cache) cache = creator();
      return cache;
    }

  });

  creator.process = function (css, processOpts, pluginOpts) {
    return postcss([creator(pluginOpts)]).process(css, processOpts);
  };

  return creator;
};

postcss.stringify = stringify;
postcss.parse = parse;
postcss.fromJSON = fromJSON;
postcss.list = list;

postcss.comment = defaults => new Comment(defaults);

postcss.atRule = defaults => new AtRule(defaults);

postcss.decl = defaults => new Declaration(defaults);

postcss.rule = defaults => new Rule(defaults);

postcss.root = defaults => new Root(defaults);

postcss.document = defaults => new Document(defaults);

postcss.CssSyntaxError = CssSyntaxError;
postcss.Declaration = Declaration;
postcss.Container = Container;
postcss.Processor = Processor;
postcss.Document = Document;
postcss.Comment = Comment;
postcss.Warning = Warning;
postcss.AtRule = AtRule;
postcss.Result = Result;
postcss.Input = Input;
postcss.Rule = Rule;
postcss.Root = Root;
postcss.Node = Node;
LazyResult.registerPostcss(postcss);
exports = postcss;
postcss.default = postcss;
export default exports;